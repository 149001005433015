<script>
  import Layout from './layout/index.svelte'
  
</script>

<main>
  <Layout />
</main>

<style>
</style>
