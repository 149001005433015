<script>
  export let width
  export let fill
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  {width}
  height={width}
  viewBox="0 0 256 256"
  ><rect x="0" y="0" {width} height={width} fill="none" stroke="none" /><path
    {fill}
    d="M216 72h-85.33l-27.74-20.8a16.12 16.12 0 0 0-9.6-3.2H40a16 16 0 0 0-16 16v136a16 16 0 0 0 16 16h176.89A15.13 15.13 0 0 0 232 200.89V88a16 16 0 0 0-16-16Zm0 128H40V64h53.33l27.74 20.8a16.12 16.12 0 0 0 9.6 3.2H216Z"
  /></svg
>
