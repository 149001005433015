<script>
  export let width
  export let fill
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  role="img"
  width={width}
  height={width}
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 512 512"
  ><path
    fill={fill}
    d="m368 350.643l-112 63l-112-63v-66.562l-32-17.778v103.054l144 81l144-81V266.303l-32 17.778v66.562z"
  /><path
    fill={fill}
    d="M256 45.977L32 162.125v27.734L256 314.3l192-106.663V296h32V162.125Zm160 142.831l-32 17.777L256 277.7l-128-71.115l-32-17.777l-22.179-12.322L256 82.023l182.179 94.463Z"
  /></svg
>
